 <template>
  <div class="date-picker">
    <template>
      <!--<flat-pickr class="ml-xs-0" :config="configFromdateTimePicker" v-model="fromDate" placeholder="From Date" @on-change="onFromChange" />
      <flat-pickr :config="configTodateTimePicker" v-model="toDate" placeholder="To Date" @on-change="onToChange" />-->
      <!-- <date-range-picker
        ref="picker"
        opens="center"
        :singleDatePicker="false"
        :timePicker="false"
        :showWeekNumbers="false"
        :showDropdowns="false"
        :autoApply="false"
        v-model="dateRange"
        @update="updateValues"
        :linkedCalendars="true"
      >
        <template
          v-slot:input="picker"
          style="min-width: 350px;"
        >{{ picker.startDate |date}} - {{ picker.endDate |date}}</template>
      </date-range-picker> -->
      <date-range-picker
          ref="picker"
          :opens="position"
          :locale-data="{ firstDay: 0, format: 'mm/dd/yyyy', separator: ' - '}"
          :dateRange="dateRange"
          v-model="dateRange"
          :alwaysShowCalendars= false
          :linked-calendars = false
          @update="updateValues"
      >
      </date-range-picker>
    </template>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    flatPickr,
    DateRangePicker
  },
  filters: {
    // date(value) {
    //   if (!value) return "";
    //   let options = { year: "numeric", month: "numeric", day: "numeric" };
    //   return Intl.DateTimeFormat("en-CA", options).format(value);
    // }
  },
  props: {
    fromDateValue: {
      type: String,
      required: true
    },
    toDateValue: {
      type: String,
      required: true
    },
    position:{
      type:String,
      default: 'center',
      required: false
    }
  },
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null
      },
      /* Date */
      // configFromdateTimePicker: {
      //   minDate: null,
      //   maxDate: null
      // },
      // configTodateTimePicker: {
      //   minDate: null
      // }
    };
  },
  // watch: {
  //     fromDateValue() {
  //         this.$emit('inputFromDate', this.fromDateValue);
  //     },
  //     toDateValue() {
  //         this.$emit('inputToDate', this.toDateValue);
  //     }
  // },
  computed: {
    fromDate: {
      get() {
        return this.fromDateValue;
      },
      set(fromDateValue) {
        this.$emit("inputFromDate", fromDateValue);
      }
    },
    toDate: {
      get() {
        return this.toDateValue;
      },
      set(toDateValue) {
        this.$emit("inputToDate", toDateValue);
      }
    }
  },
  created() {
    this.dateRange.startDate = this.fromDate;
    this.dateRange.endDate = this.toDate;
  },
  methods: {
    /* Date */
    /*onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },*/
    updateValues(values) {
      // this.dateRange.startDate = moment(values.startDate).format("YYYY-MM-DD");
      // this.dateRange.endDate = moment(values.endDate).format("YYYY-MM-DD");
      // this.$emit("inputFromDate", values.startDate);
      // this.$emit("inputToDate", values.endDate);
      // this.$store.dispatch("startDateChange", moment(values.startDate).format("YYYY-MM-DD"));
      // this.$store.dispatch("endDateChange", moment(values.endDate).format("YYYY-MM-DD"));
      this.dateRange.startDate = moment(values.startDate).format("MM/DD/YYYY");
      this.dateRange.endDate = moment(values.endDate).format("MM/DD/YYYY");
      this.$emit("inputFromDate", values.startDate);
      this.$emit("inputToDate", values.endDate);
      this.$store.dispatch("startDateChange", moment(values.startDate).format("MM/DD/YYYY"));
      this.$store.dispatch("endDateChange", moment(values.endDate).format("MM/DD/YYYY"));
    }
  }
};
</script>