<template>
  <div>
    <!-- Customer Activity Report date filter -->
    <vx-card class="mt-8">
      <div class="card-title justify-between">
        <h2 class="w-auto">Customer Activity Report</h2>
      </div>
      <div class="review-location">
        <div class="vx-row">
          <div class="vx-col w-full">
            <date-range-picker
              :fromDateValue="fromDate"
              :toDateValue="toDate"
              @inputFromDate="(newFromDate) => {fromDate = newFromDate}"
              @inputToDate="(newToDate) => {toDate = newToDate}"
              class="p-0 sm:w-auto w-full"
            ></date-range-picker>
            <vs-button            
              type="filled"
              class="small-btn sm:ml-2 sm:mt-0 ml-0 mt-2 sm:w-auto w-full"
              @click="getAcitivityReport()"
            >{{ LabelConstant.buttonLabelRunQuery }}</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- Customer Activity Table -->
    <vx-card class="mt-8">
      <div class="card-title justify-between">
        <h2 class="w-auto">Customer Activity</h2>
      </div>
      <div class="common-table">
        <div class="table-row-select">
          <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in recordList"
            />
          </vs-select>
        </div>
        <vs-table :data="activeCustomers" :max-items="perPage" pagination search>
          <template slot="thead">
            <vs-th sort-key="Company_Name">{{ LabelConstant.tableHeaderLabelCustomerName }}</vs-th>
            <vs-th sort-key="Free_Trial">{{ LabelConstant.tableHeaderLabelStatus }}</vs-th>
            <vs-th sort-key="Date_Created">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
            <vs-th sort-key="Date_First_Traffic">{{ LabelConstant.tableHeaderLabelDateFirstTraffic }}</vs-th>
            <vs-th sort-key="Free_Trial_Expiration">{{ LabelConstant.tableHeaderLabelFreeTrialExp }}</vs-th>
            <vs-th sort-key="Number_of_Users">{{ LabelConstant.tableHeaderLabelUsers }}</vs-th>
            <vs-th sort-key="Billing_Users_Additional">{{ LabelConstant.tableHeaderLabelPurchasedUsers }}</vs-th>
            <vs-th sort-key="Number_of_Notifications">{{ LabelConstant.tableHeaderLabelNotifiations }}</vs-th>
            <vs-th sort-key="Number_of_Estimated_Monthly_Requests">{{ LabelConstant.tableHeaderLabelPageViews }}</vs-th>
            <vs-th sort-key="Domain_Name">{{ LabelConstant.tableHeaderLabelDomainName }}</vs-th>
            <vs-th sort-key="Company_Id">{{ LabelConstant.tableHeaderLabelAccountId }}</vs-th>
            <vs-th sort-key="First_Name">{{ LabelConstant.tableHeaderLabelFirstName }}</vs-th>
            <vs-th sort-key="Last_Name">{{ LabelConstant.tableHeaderLabelLastName }}</vs-th>
            <vs-th sort-key="Email">{{ LabelConstant.tableHeaderLabelEmail }}</vs-th>
            <vs-th sort-key="Phone">{{ LabelConstant.tableHeaderLabelPhone }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.Company_Name">{{tr.Company_Name}}</vs-td>
              <vs-td :data="tr.Free_Trial">
                <vs-chip color="success" v-if="!tr.Free_Trial">Paid</vs-chip>
                <vs-chip color="warning" v-else>Free Trial</vs-chip>
              </vs-td>
              <vs-td :data="tr.Date_Created">{{tr.Date_Created}}</vs-td>
              <vs-td :data="tr.Date_First_Traffic">{{tr.Date_First_Traffic}}</vs-td>
              <vs-td :data="tr.Free_Trial_Expiration">{{tr.Free_Trial_Expiration}}</vs-td>
              <vs-td :data="tr.Number_of_Users">{{tr.Number_of_Users}}</vs-td>
              <vs-td :data="tr.Billing_Users_Additional">{{tr.Billing_Users_Additional}}</vs-td>
              <vs-td :data="tr.Number_of_Notifications">{{tr.Number_of_Notifications}}</vs-td>
              <vs-td :data="tr.Number_of_Estimated_Monthly_Requests">
                <vs-chip color="success" v-if="tr.Number_of_Estimated_Monthly_Requests < 17000">{{tr.Number_of_Estimated_Monthly_Requests}}</vs-chip>
                <vs-chip color="warning" v-if="tr.Number_of_Estimated_Monthly_Requests > 17000 && tr.Number_of_Estimated_Monthly_Requests < 20000">{{tr.Number_of_Estimated_Monthly_Requests}}</vs-chip>
                <vs-chip color="danger" v-if="tr.Number_of_Estimated_Monthly_Requests > 20000">{{tr.Number_of_Estimated_Monthly_Requests}}</vs-chip>
              </vs-td>
              <vs-td :data="tr.Domain_Name">{{tr.Domain_Name}}</vs-td>
              <vs-td :data="tr.Company_Id">{{tr.Company_Id}}</vs-td>
              <vs-td :data="tr.First_Name">{{tr.First_Name}}</vs-td>
              <vs-td :data="tr.Last_Name">{{tr.Last_Name}}</vs-td>
              <vs-td :data="tr.Email">{{tr.Email}}</vs-td>
              <vs-td :data="tr.Phone">{{tr.Phone}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>
<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import DateRangePicker from "../../components/filter/DateRangePicker";

export default {
  components: {
    VxCard,
    vSelect,
    DateRangePicker
  },
  data() {
    return {
      /* Table Selcet Row */
      perPage: 5,
      recordList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
      //From Date
      fromDate: null,
      //To Date
      toDate: null,
      /* Table */
      activeCustomers: []
    };
  },
  mounted() {
    var menuitem = document.getElementsByClassName("vs-sidebar-group ");
    //This is usefull to stay open Reseller menu and selected Reseller Reports
    menuitem.forEach(element => {
      var arrow = element.getElementsByClassName(
        "feather-icon select-none relative feather-grp-header-arrow"
      );
      var text = element.getElementsByClassName("truncate mr-3 select-none")[0]
        .innerText;
      var uls = element.getElementsByTagName("ul");
      if (text == "Reseller") {
        element.className += " vs-sidebar-group-open";
        arrow[0].className += " rotate90";
        uls[0].style.maxHeight = "none";

        uls.forEach(elementInner => {
          var lis = elementInner.getElementsByTagName("li");
          lis.forEach(elementInnerInner => {
            var textInner = elementInnerInner.innerText;
            var a = elementInnerInner.getElementsByTagName("a")
            if(textInner == 'Reseller Reports'){
              a[0].className += " router-link-exact-active router-link-active";
            }
          })  
        })
      }
    });
  },
  //This is usefull to close Reseller menu and unselected Reseller Reports when leave the menu
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      let menuitem = [];
      menuitem = document.getElementsByClassName("vs-sidebar-group ");
      menuitem.forEach(element => {
        if (element.classList.contains("vs-sidebar-group-open")) {
          let text = element.getElementsByClassName(
              "truncate mr-3 select-none"
          )[0].innerText;
          let uls = element.getElementsByTagName("ul");
          let arrow = element.getElementsByClassName(
              "feather-icon select-none relative feather-grp-header-arrow"
          );
          let det = to.path;
          det = det.match("/reseller");
          if (text == "Reseller" && !det) {
            uls[0].style.maxHeight = 0;
            arrow[0].classList.remove("rotate90");
            element.classList.remove("vs-sidebar-group-open");

            uls.forEach(elementInner => {
              var lis = elementInner.getElementsByTagName("li");
              lis.forEach(elementInnerInner => {
                var textInner = elementInnerInner.innerText;
                var a = elementInnerInner.getElementsByTagName("a")
                if(textInner == 'Reseller Reports'){
                  a[0].className = "";
                }
              })  
            })
          }
        }
      });
    }, 300);
    next();
  },
  created() {
    this.setFromDate();
    this.setToDate();
    this.getAcitivityReport();
  },
  methods: {
    //Get activity report data
    async getAcitivityReport() {
      this.$vs.loading();
      let activityReport = {
        Date_Start: this.fromDate,
        Date_End: this.toDate
      };
      await this.axios.post("/ws/ResellerReportingCustomerActivity/GetCustomerActivityList",activityReport)
        .then(response => {
          let data = response.data;
          this.activeCustomers = data;
          this.activeCustomers.sort(this.GetSortOrder("Company_Name", "DESC"));
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    }
  }
};
</script>
